import styled from 'styled-components'
import { nav } from 'tiny-react-router'
import { 
  AiOutlineMail,
  AiOutlineMobile 
} from "react-icons/ai"
import { GiDeliveryDrone } from "react-icons/gi"
import { 
  useAccountStore,
  useProductStore
} from '../../shared/state'
import Vipps from '../../shared/graphics/vipps.svg'
import Roaster from './graphics/roaster.png'
import Utsolgt from '../../shared/graphics/utsolgt.png'
import CampaignSection from '../../shared/components/CampaignSection'

export default function Main() {
  const account = useAccountStore(s => s.account)
  const products = useProductStore(s => s.products)

  const handleLoginVipps = () => {
    window.location = '/api/login/vipps/request'
  }

  const handleOrderClick = () => {
    if (account != null) return nav('/account')
    else handleLoginVipps()
  }

  const productSections = products
    .filter(p => p?.data?.show !== false)
    .sort((a,b) => {
      if (a?.data?.order > b?.data.order) return 1
      if (a?.data?.order < b?.data.order) return -1
      return 0
    })
    .map((p,i) => {
      let hovdingimagestyle = {}
      if (p?.data?.name === 'Høvdingen') hovdingimagestyle = {
//        transform: "translateY(5%)"
        paddingTop: '5%'
      }
      return (
        <ProductSection key={p.id} flip={i%2} bgc={`${p?.data?.color}`}>
          <ProductDetailsContainer color={p?.data?.fontcolor}>
            <ProductLabelContainer index={i}>
              <ProductLabel index={i}>{p?.data?.name}</ProductLabel>
            </ProductLabelContainer>
            <ProductDetail>
              <label>Land</label>
              <div>{p?.data?.country}</div>
            </ProductDetail>
            <ProductDetail>
              <label>Område</label>
              <div>{p?.data?.area}</div>
            </ProductDetail>
            <ProductDetail>
              <label>Prosess</label>
              <div>{p?.data?.processing}</div>
            </ProductDetail>
            <ProductDetail>
              <label>Moh.</label>
              <div>{p?.data?.altitude}</div>
            </ProductDetail>
            <ProductDetail>
              <label>Innhøsting</label>
              <div>{p?.data?.crop_year}</div>
            </ProductDetail>
            <ProductDetail>
              <label>Mengde</label>
              <div>{`${p?.data?.weight}g`}</div>
            </ProductDetail>
            <ProductDetail>
              <label>Pris</label>
              <div>{`NOK ${p?.data?.price},-`}</div>
            </ProductDetail>
            <ProductDescription>
              <label>Cupping</label>
              <div>{p?.data?.description}</div>
            </ProductDescription>
            <ProductLink target="_blank" href={p?.data?.link}>Les mer...</ProductLink>
          </ProductDetailsContainer>
          <ProductPitchContainer color={p?.data?.fontcolor}>
            {p?.data?.pitch}
          </ProductPitchContainer>
          <ProductImageContainer style={hovdingimagestyle}>
            <img src={p?.data?.decal} alt="odin-decal" />
          </ProductImageContainer>
          {p?.data?.sold_out &&
          <UtsolgtImage src={Utsolgt} />
          }
        </ProductSection>
      )
    })

  return (
    <Container>
      <Section bgc="var(--color-splash-yellow)">
        <SplashImage src={Roaster} />
        <SplashText>
          <h1>Bøllefrø</h1>
          <h2>Spesialkaffe, enkelt.</h2>
          <p>
            Bøllefrø er et mikrorøsteri.<br/>
            Vi røster og leverer kaffe av topp kvalitet som du elsker.<br/>
            Vårt mål er å få spesialkaffe hjem til deg så enkelt som mulig. 
          </p>
          <div className="buttons">
            <button onClick={handleOrderClick}>Bestill nå!</button>
            { !account &&
            <VippsLoginButton onClick={handleLoginVipps}>
              Login med <img src={Vipps} alt="vipps" />
            </VippsLoginButton>
            }
          </div>
        </SplashText>
      </Section>
      <CampaignSection />
      {productSections}
      <ContactSection bgc="var(--color-splash-purple)" color="white">
        <ContactContainer>
          <h2>Frakt</h2>
          <div>
Vi leverer kaffen rett i din postkasse.<br /><br />
Dersom du bor på Nord-Jæren (Fra Randaberg til Bryne) så leverer vi gratis.
Bor du et annet sted i landet kommer NOK 50,- i tillegg for frakt.
          </div>
          <p>
Vi leverer / sender så raskt som mulig etter at din bestilling er betalt.
          </p>
        </ContactContainer>
        <DeliverImageContainer>
          <GiDeliveryDrone size="1em" />
        </DeliverImageContainer>
      </ContactSection>
      <ContactSection bgc="var(--color-splash-purple)" color="white">
        <ContactContainer>
          <h2>Kontakt oss</h2>
          <div>Dersom du...
            <ul>
              <li>Ønsker å bestille bedriftsabonnement</li>
              <li>Har problemer med nettsiden</li>
              <li>Har spørsmål rundt en ordre</li>
              <li>Har spørsmål rundt en leveranse</li>
              <li>Eller noe som helst annet</li>
            </ul>
            ...ikke nøl med å ta kontakt med oss!
          </div>
        </ContactContainer>
        <ContactContainer>
          <ContactGroupContainer>
            <ContactGroup>
              <AiOutlineMobile size="1em" />
              <a href="tel:+4747011370">+47 47011370</a>
            </ContactGroup>
            <ContactGroup>
              <AiOutlineMail size="1em" />
              <a href="mailto:support@bard.coffee">support@bard.coffee</a>
            </ContactGroup>
          </ContactGroupContainer>
        </ContactContainer>
      </ContactSection>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const Section = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  padding: 4% 2%;
  gap: 5%;
  position: relative;
  ${props => props.bgc ? `background-color: ${props.bgc}` : ''};
  ${props => props.color ? `color: ${props.color}` : ''};
`

const ProductSection = styled(Section)`
  padding: 4% 4%;
  ${props => props.flip === 1 ? 'flex-direction: row-reverse; justify-content: flex-end;' : ''}
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
  }
`

const ContactSection = styled(Section)`
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
  }
`

const SplashImage = styled.img`
  width: 300px;
  height: 270px;
  @media (max-width: 768px) {
    width: 160px;
    height: 145px;
  }
`

const SplashText = styled.div`
  @media (max-width: 768px) {
    p {
      display: none;
      font-size: 0.7em;
    }
  }
  & h1 {
    margin-bottom: 0px;
    @media (max-width: 768px) {
      font-size: 1.2em;
    }
  }
  & h2 {
    font-style: italic;
    margin-top: 10px;
    @media (max-width: 768px) {
      font-size: 1em;
    }
  }
  & .buttons {
    display: flex;
    flex-direction: row;
    gap: 5px;
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
  & button {
    color: white;
    background-color: var(--color-splash-purple); 
    padding: 8px 10px;
    border: 2px solid white;
    border-radius: 10px;
    font-weight: bold;
    cursor: pointer;
  }
`

const VippsLoginButton = styled.div`
  color: white;
  background-color: var(--color-vipps);
  border-radius: 10px;
  border: 2px solid white;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  padding: 5px 10px;
  cursor: pointer;
  & img {
    height: 10px;
    margin-top: 5px;
    padding: 5px;
  }
`

const ProductImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
  flex-shrink: 0;
  flex-grow: 0;
  @media (max-width: 768px) {
    width: 100%;
  }
  & img {
    height: auto;
    width: 100%;
  }
`

const ProductDetailsContainer = styled.div`
  width: 30%;
  flex-shrink: 0;
  flex-grow: 0;
  ${props => props.color ? `color: ${props.color};` : ''}
  @media (max-width: 768px) {
    width: 100%;
  }
`

const ProductLabelContainer = styled.div`
  margin-bottom: 10px;
`

const ProductLabel = styled.div`
  font-family: 'Bilbo Swash Caps', cursive;
  font-weight: bold;
  font-size: ${props => {
    if ([1].indexOf(props.index) >= 0) return '2.7em'
    if ([3].indexOf(props.index) >= 0) return '2.5em'
    return '4em'
  }}; 
  white-space: nowrap;
`

const ProductDetail = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  padding: 2px 0px;
  font-family: monospace;
  & label {
    width: 100px;
    font-weight: bold;
  }
`

const ProductDescription = styled.div`
  & label {
    width: 100px;
    font-family: monospace;
    font-weight: bold;
    @media (max-width: 768px) {
      width: 50px;
    }
  }

  & div {
    margin-top: 5px;
    font-size: 0.8em;
    @media (max-width: 768px) {
      font-size: 1em;
    }
  }
`

const ProductLink = styled.a`
  display: block;
  margin-top: 20px;
  text-decoration: none;
  width: 70px;
  font-weight: bold;
  font-size: 0.8em;
  @media (max-width: 768px) {
    margin-top: 10px;
  }
`

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-bottom: 50px; 
  width: 50%;
  white-space: normal;
  @media (max-width: 768px) {
    width: 95%;
    padding: 4%;
    padding-bottom: 10px;
  }
`

const ContactGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const ContactGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 205px;
  gap: 10px;
  margin-bottom: 10px;
  & svg {
    font-size: 3em;
  }
`

const DeliverImageContainer = styled.div`
  flex-shrink: 0;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  & svg {
    font-size: 10em;
    @media (max-width: 768px) {
      font-size: 5em;
    }
  }
`

const ProductPitchContainer = styled.div`
  font-family: 'Bilbo Swash Caps', cursive;
  width: 30%;
  flex-shrink: 0;
  flex-grow: 0;
  font-size: 2em;
  ${props => props.color ? `color: ${props.color};` : ''}
  @media (max-width: 768px) {
    width: 100%; 
  }
`

const UtsolgtImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  @media (max-width: 768px) {
    top: 10%;
    width: 80%;
    transform: translate(-50%, 10%);
  }
`
